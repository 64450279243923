<template>
  <v-container fluid class="pa-12 h-100">
    <v-row align-content="center" justify="center">
      <v-col cols="12">
        <Suspense>
          <template #default>
            <Transition
              mode="out-in"
              @leave="onLeave"
              @enter="onEnter"
              :css="false"
            >
              <KeepAlive>
                <Component :is="props.component"></Component>
              </KeepAlive>
            </Transition>
          </template>
          <template #fallback>Loading...</template>
        </Suspense>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";

import anime from "animejs";

const props = defineProps({
  component: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(["animation:finished"]);

const onEnter = (el, done) => {
  const animation = anime
    .timeline({
      targets: ".transition-sheet",
      duration: 500,
      translateY: [0, 1000],
      easing: "easeInOutSine",
      complete: done,
    })
    .add({
      targets: el,
      duration: 500,
      opacity: [0, 1],
      complete: done,
    });

  animation.finished.then(() => emit("animation:finished"));
};

const onLeave = (el, done) => {
  anime
    .timeline({
      targets: ".transition-sheet",
      duration: 500,
      translateY: [-1000, 0],
      easing: "easeInOutSine",
      complete: done,
    })
    .add({
      targets: el,
      duration: 500,
      opacity: [1, 0],
      complete: done,
    });
};
</script>
<style scoped></style>
