import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/about",
  },
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/about"),
  },
  {
    path: "/creative",
    name: "Creative",
    component: () => import("@/views/creative"),
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import("@/views/blog"),
  },
  {
    path: "/blog/:id",
    name: "BlogPost",
    component: () => import("@/views/blog/_slug"),
  },
  {
    path: "/photography",
    name: "Photography",
    component: () => import("@/views/photography"),
  },
  {
    path: "/resume",
    name: "Resume",
    component: () => import("@/views/resume"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior: () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ top: 0 });
      }, 500);
    });
  },
  routes,
});

export default router;
