import { defineStore } from "pinia";

export const useAppStore = defineStore("app", {
  state: () => ({
    navDrawerIsOpen: false,
  }),
  getters: {
    getNavDrawerIsOpen: (state) => state.navDrawerIsOpen,
  },
  actions: {
    toggleNavDrawer() {
      this.navDrawerIsOpen = !this.navDrawerIsOpen;
    },
  },
});
