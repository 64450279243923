<template>
  <v-app full-height class="overflow-y-hidden">
    <NavBar :routes="routes" :current="currentRoute" />
    <NavDrawer :routes="routes" />
    <v-main>
      <RouterView v-slot="{ Component }">
        <Section v-if="Component" :component="Component" />
      </RouterView>
    </v-main>

    <!-- <TransitionSheet v-if="transitionSheet" :routeName="currentRoute" /> -->
  </v-app>
</template>

<script setup>
import NavBar from "@/components/NavBar";
import NavDrawer from "@/components/NavDrawer";
// import TransitionSheet from "@/components/TransitionSheet";
import Section from "@/components/Section";

import { usePhotographyStore } from "@/stores/photography.js";

import { computed, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const routes = ref(["About", "Creative", "Resume", "Blog"]);
const photographyStore = usePhotographyStore();

const currentRoute = computed(() => route.name);
// const transitionSheet = ref(true);

// const setTransitionSheet = () => {
//   transitionSheet.value = false;

//   setTimeout(() => (transitionSheet.value = true), 50);
// };

photographyStore.fetchAllImages();
</script>

<style>
@import "@/assets/css/main.css";
</style>
