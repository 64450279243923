import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";

import { createApp } from "vue";
import { createPinia } from "pinia";
import { VueFire } from "vuefire";
import { firebaseApp } from "./plugins/firebase";
import { loadFonts } from "./plugins/webfontloader";

const pinia = createPinia();

loadFonts();

createApp(App)
  .use(pinia)
  .use(vuetify)
  .use(router)
  .use(VueFire, { firebaseApp })
  .mount("#app");
