<template>
  <v-navigation-drawer v-model="store.getNavDrawerIsOpen">
    <v-list>
      <v-list-item
        v-for="route in routes"
        :key="route"
        :title="route"
        :to="{ name: route }"
        ripple
        @click="store.toggleNavDrawer()"
      >
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script setup>
import { defineProps } from "vue";
import { useAppStore } from "@/stores/app.js";

const { routes } = defineProps({
  routes: {
    type: Array,
    default: () => [],
  },
});

const store = useAppStore();
</script>
<style scoped></style>
