import { defineStore } from "pinia";
import { useCollection } from "vuefire";
import { images } from "@/plugins/firebase";

export const usePhotographyStore = defineStore("photography", {
  state: () => ({
    images: [],
  }),
  getters: {
    getImages: (state) => state.images,
  },
  actions: {
    fetchAllImages() {
      try {
        this.images = useCollection(images);
      } catch (err) {
        throw new Error(`! @store:photographyStore::fetchAllImages ${err}`);
      }
    },
  },
});
